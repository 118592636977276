* :focus {
  outline: none !important;
}

.confirm {
  text-align: center;
}

.confirm i.flaticon2-information {
  font-size: 70px;
}

.confirm p {
  font-size: 14px;
}

.box__document_repo {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}
@keyframes fade {
  0%{
    opacity: 0;
  }100%{
    opacity: 1;
  }
}
.document_repo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: 11px;
  background: none;
  border: none;
  color: #333;
  font-style: italic;
  width:100%;
  max-width: 200px;
  margin: 15px 0;
  animation: fade 300ms linear;
}
@media screen and (max-width: 440px) {
  .document_repo {
    max-width: 130px;
  }
}
.document_repo img {
  margin-bottom: 7.5px;
}
.document_repo button {
  border:1px solid transparent;
  background: none;
  transition: 150ms;
  text-align: center;
}
.document_repo button:hover {
  border:1px solid #999;
}
.document_repo input {
  border:1px solid #999;
  text-align: center;
}
.document_repo button.delete_document {
  background:none;
  padding:0;
  border: 0;
  color:#db3232;
  border-radius:50%;
}
.document_repo button.delete_document:hover {
  border:0;
  color:#fff;
  background:#db3232;
}
button.trash_button {
  background:none;
  border:0;
  color:#595d6e;
  font-size:1rem;
  font-weight: 400;
}
.document_repo button.restore_document {
  background:none;
  padding:0;
  border: 0;
  color:#326ddb;
  border-radius:50%;
}
.document_repo button.restore_document:hover {
  border:0;
  color:#fff;
  background:#326ddb;
}
.delete_document:disabled, .restore_document:hover {
  opacity: 0.5;
}
.delete_document {
  background:#fff;
  padding:0;
  border: 0;
  color:#db3232;
  border-radius:50%;
  transition: 300ms;
}
.delete_document:hover {
  border:0;
  color:#fff;
  background:#db3232;
}
.move_document {
  background:#fff;
  padding:0;
  border: 0;
  color:#326ddb;
  transition: 300ms;
}
.move_document:hover {
  border:0;
  color:#fff;
  background:#326ddb;
}
.counter_items_folder {
  position: absolute;
  line-height: 22px;
    top: 22px;
    right: 66px;
    background: #327ad9;
    color: #fff;
    width: 22px;
    height: 22px;
    font-weight: bolder;
    border-radius: 50%;
    font-size: 13px;
}

.notification_floated {
  background: #fff;
  position:absolute;
  z-index: 10;
  top:80px;
  box-shadow: 0 0 5px rgba(0,0,0,.3);
  right: 10px;
  width:300px;
  max-width: 350px;
  min-height: 200px;
  max-height: 350px;
  overflow: auto;
}
.notification_floated div + div {
  border-top:1px solid rgba(0,0,0,0.2);
}
.notification_floated > div:nth-child(odd) {
  background: #f3f3f3;
}
.notification_floated div {
  padding:10px;
  transition:300ms;
  text-align: right;
  line-height: 1;
}
.notification_floated div h4 {
  font-size:12px;
  margin: 0 0 5px;
}
.notification_floated div button {
  background:none;
  border:none;
  color:#333;
  border: 1px solid #333;
  border-radius:4px;
  font-weight: 1000;
  padding:5px 15px;
  margin:0;
  font-size:11px;
  transition:300ms;
}
.notification_floated div button:hover {
  background:#333;
  color:#f3ac42;
}

@keyframes fadeCounter {
  0%{
    opacity: 0;
    transform: scale(1.2);
  }100%{
    opacity: 1;
    transform: scale(1);
  }
}

.counter_notification {
  position:absolute;
  animation:fadeCounter 300ms linear;
  top:25%;
  right:18%;
  font-weight: bolder;
  background:#f3ac42;
  padding:0px 3px;
  height:20px;
  width:20px;
  font-size:12px;
  border-radius:50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.opinions_list_item {
  width: 100%;
  display:flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  border:none;
  padding: 15px;
  transition:300ms;
}
.opinions_list_item:hover {
  background:#f5f5f5;
}

.opinions_list_item .opinions_list_item_wrap {
  display:flex;
  justify-content: center;
  align-items: center;
}
.opinions_list_item .opinions_list_item_wrap div {
  margin:0 5px;
}
button.opinions_list_item + button.opinions_list_item {
  border-top:1px solid #eee;
}

.opinion_master_container {
  background: #f5f5f5;
  padding: 15px;
  border-radius:4px;
  margin:20px 10px;
  box-shadow: 0 0 5px rgba(0,0,0,.15);
}
.opinion_master_approved {
  border:2px solid rgba(29, 201, 183, 0.4);
}
@keyframes fade_opinion {
  0%{
    opacity: 0;
  }100%{
    opacity:1;
  }
}
.opinion_master {
  animation:fade_opinion 300ms linear;
  width: 100%;
  display:flex;
  justify-content: space-between;
  align-items: center;
  background: #f5f5f5;
  border-bottom: 1px solid #c9c9c9;
  padding-bottom:10px;
  margin-bottom:10px;
}
.opinion_master_wrap {
  display:flex;
  justify-content: center;
  align-items: center;
}
.opinion_master_wrap div {
  margin-left:10px;
  font-weight: bold;
}
.opinion_date {
  display:block;
  text-align:right;
}
.opinion_input {
  width:100%;
  margin:20px;
  padding:30px;
  background:#f5f5f5;
  border-radius:4px;
  box-shadow: 0 0 5px rgba(0,0,0,.15);
  display:flex;
  justify-content: center;
  align-items: flex-end;
}
.opinion_input div {
  display:block;
}
.opinion_input div button {
  margin-left:10px;
  display:block;
  width:100%;
}
.opinion_input button + button {
  margin-top:10px;
}

.approve_item {
  padding:10px;
}
.approve_item div small {
  margin-top:auto;
}
.approve_item div {
  padding:15px;
  flex:1;
  border-radius:4px;
  border:2px solid #f3ac42;
  color:#666;
  opacity:0.7;
}
.approved div {
  border:2px solid #1dc97e;
  opacity:1;
}